import * as Sentry from "@sentry/browser";

const observer = new MutationObserver(() => {
  if (typeof sentry_js_options !== 'undefined') {
    Sentry.init(sentry_js_options);
    observer.disconnect();
  }
});

observer.observe(document.head, {
  childList: true,
});

document.addEventListener('DOMContentLoaded', () => {
  observer.disconnect();
});